header .header-top {
  display: block;
  background: #ff5015;
  color: #ffffff;
  /* height: 26px; */
  padding: 12px;
  width: 100%;
  top: 0;
  -webkit-box-shadow: 0px 0px 4px #adadad;
          box-shadow: 0px 0px 4px #adadad;
  transition: 0.5s all ease-in-out;
}
header.scrolled .header-top {
  display: none;
  padding: 0px;
  height: 0;
}
.header-top, .header-top a {
  color: #ffffff;
  font-size: 16px;
}
.header-top, .header-top a span {
  padding-left: 8px;
}
.header-top a:hover {
  text-decoration: none;
  /* color: #ffdf01; */
  text-shadow: 0px 0px 4px #ffdf01;
}
.b-dark {
  background: linear-gradient(rgba(255, 255, 255, 1),rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.6),rgba(255, 255, 255, 0.4),rgba(255, 255, 255, 0));
  /* background: rgba(255, 255, 255, 0.8); */
  /* padding: 16px 0; */
  transition: 75ms all ease-in-out;
  -webkit-transition: 75ms all ease-in-out;
  -moz-transition: 75ms all ease-in-out;
  -ms-transition: 75ms all ease-in-out;
  -o-transition: 75ms all ease-in-out;
}

header.scrolled .b-dark {
  background: rgba(255, 255, 255, 1);
  /* -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px); */
  -webkit-box-shadow: 0px 0px 4px #adadad;
          box-shadow: 0px 0px 4px #adadad;
}

.b-dark .subtitle {
  font-size: 12px;
}

.hirebtnup {
  display: inline;
}

.hirebtndn {
  display: none;
}

.social-links {
  text-decoration: none;
}

.social-links a {
  font-size: 18px;
  display: inline-block;
  background: #ffffff;
  color: #ff5015;
  line-height: 1;
  padding: 6px 0;
  margin-right: 2px;
  text-align: center;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  border: 2px solid #ffffff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.social-links a:hover {
  color: #00e11e;
  text-decoration: none;
  border: 2px solid #ffdf01;
  /* box-shadow: 0px 0px 4px #00e11e; */
}

.f-link a {
  color: #ba0005;
}
.icon-bar {
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.45);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  overflow: auto;
  padding: 10px 0;
}

.icon-bar a {
  float: left;
  background-color: rgba(58, 58, 58, 0.6);
  width: 12%;
  text-align: center;
  margin-right: 6px;
  padding: 8px 0;
  transition: all 0.3s ease;
  color: white;
  border-radius: 10%;
  font-size: 18px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-border-radius: 10%;
  -moz-border-radius: 10%;
  -ms-border-radius: 10%;
  -o-border-radius: 10%;
}

.icon-bar a:hover {
  background: #ffffff;
}

.icon-bar a:last-child {
  margin-right: 0px;
}

.icon-bar .active {
  background: rgba(52, 152, 219, 0.8);
  width: 31%;
}

.top-nav-collapse {
  background-color: transparent;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

footer {
  background: #222222;
  color: #adadad;
  padding: 32px 0;
  font-size: 16px;
  border-top: 4px solid #ffdf01;
}

footer a {
  color: #ff5015;
  text-decoration: underline;
}

/*----------------Responsive----------------*/
@media only screen and (min-width: 768px) {
  .b-dark {
    height: 80px;
  }
  header.scrolled .b-dark {
    height: 70px;
  }
}

@media only screen and (max-width: 768px) {
  header .header-top {
    display: none;
  }
  .b-dark {
    background: rgba(255, 255, 255, 1);
    /* -webkit-backdrop-filter: saturate(180%) blur(20px); */
    backdrop-filter: saturate(180%) blur(0px);
    -webkit-box-shadow: 0px 0px 4px #adadad;
    box-shadow: 0px 0px 4px #adadad;
  }
  .b-dark.scrolled {
    background: rgba(225, 255, 255, 0.65);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
  }
  .hirebtnup {
    display: none;
  }
  .hirebtndn {
    display: inline;
  }
  footer {
    line-height: 2;
    padding: 32px 0;
  }
  [data-aos-delay] {
    -webkit-transition-delay: 0 !important;
            transition-delay: 0 !important;
  }
}

.dev-cred p {
  margin: 0;
}
.dev-cred p a {
  font-size: 12px;
  text-decoration: none;
  color: #adadad;
}
.dev-cred p a:focus, .dev-cred p a:hover {
  color: #ff5015; }