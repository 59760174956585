@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

main {
  background: rgb(225, 255, 255);
}
.navbar a, button i, a.navbar-brand {
  color: #363636;
}
.navbar a.selected {
  font-weight: bold;
  color: #ff5015;
}
a.btn {
  background: #ff5015;
  color: rgb(255, 255, 255);
  font-weight: bold;
  box-shadow: 2px 2px #ff5015;
}
a.btn:hover {
  color: rgb(230, 230, 230);
  background: rgb(54, 54, 54);
  box-shadow: 1px 1px rgb(54, 54, 54);
}
a.navbar-brand:hover {
  color: #ff5015;
  text-shadow: 0px 1px 5px #ffdf01;
}
.navbar-nav li a {
  background: rgba(255, 255, 255,0.9);
  font-weight: bold;
  padding: 10px 0;
  /* border-top: 4px solid rgba(255, 255, 255,0.9); */
  border-bottom: 4px solid rgba(255, 255, 255,0.9);
}
header.scrolled .navbar-nav li a {
  padding: 21px 0;
}
.navbar-nav li a:hover {
  /* background: linear-gradient(#ffffff,#ff5015); */
  border-bottom: 4px solid #ffdf01;
}
.navbar-nav li a.active {
  background: #ff5015;
  color: #ffffff;
  /* border-top: 4px solid #ff5015; */
  border-bottom: 4px solid #00e11e;
}

section {
  font-family: 'Montserrat', sans-serif;
    width: 100%;
    margin: 0 auto;
    display: grid;
    place-content: center;
    z-index: 2;
    position: relative;
}
.section-bg {
    background-color: #f7fbfe;
}
.section-title {
    text-align: center;
    padding-bottom: 30px;
}
.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.section-title p {
    margin: 15px 0 0 0;
}

.home {
    height: 90vh;
    /* padding: 160px 0 120px 0; */
    background-image: url('../media/hero_bg.jpg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    color: #292929;
    position: relative;
}
.home .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:  translate(-50%, -50%);
    -webkit-transform:  translate(-50%, -50%);
    -moz-transform:  translate(-50%, -50%);
    -ms-transform:  translate(-50%, -50%);
    -o-transform:  translate(-50%, -50%);
}

.home .credit {
  background: transparent;
  padding: 0;
  border: #800000 0px solid;
  border-radius: 20px;
  width: 72%;
  margin: 0 auto;
}

.home h1 {
  color: #ffffff;
  text-shadow: 0px 1px 15px #363636;
}

.home span.Typewriter__wrapper, .home span.Typewriter__cursor {
  font-size: 45px;
  color: #ffffff;
  text-shadow: 0px 1px 15px #363636;
}

.home .container img {
  width: 280px;
  bottom: -85px;
  position: relative;
}

.vsdiv {
  background: #ffffff;
}

.vsdiv .vidhansabha {
  bottom: 85px;
  position: relative;
  background: #ff5015;
  color: #ffffff;
  padding: 10px 10px;
  text-align: center;
  font-size: 32px;
  z-index: 20;
  box-shadow: 0px 1px 15px #ff4500;
  border-radius: 36px 36px;
  border-bottom: #00ff22 6px solid;
}
.vsdiv .vidhansabha .col-lg-5 {
  /* background: #00ff00; */
  border-right: #ffffff 2px solid;
}
.vsdiv .vidhansabha img {
  width: 60px;
}
.vsdiv .vidhansabha img.symbol {
  width: 28%;
  /* -webkit-filter: grayscale(100);
  filter: grayscale(100); */
  transition: all 0.4s ease-in-out;
  display: inline-block;
  /* padding-bottom: 8px; */
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.vsdiv .vidhansabha img.symbol:hover {
  -webkit-filter: none;
  filter: none;
  transform: scale(1.1);
}
.vsdiv .vidhansabha a {
  color: #ffffff;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}
.vsdiv .vidhansabha a:hover img {
  transform: scale(1.1);
}

.home-about {
  padding: 20px 0 100px 0;
}
.h2-head::after {
  content: "";
  display: block;
  width: 45px;
  padding-top: 25px;
  border-bottom: 2px solid #ff5015;
}
.home-about h5 {
  color: #ff5015;
  text-transform: uppercase;
  font-weight: bold;
}
.home-about p {
  color: #808080;
}

.home-about img, .about img {
  width: 240px;
  box-shadow: 4px 4px 10px #ff4500;
  border: 1px solid #ff4500;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.testimonials .container {
  background: rgb(0, 140, 255);
  /* bottom: 45px; */
  z-index: 20;
  position: relative;
}

.about {
  /* height: 90vh; */
  /* padding-top: 15rem; */
  background: #ffffff;
  color: #292929;
  padding: 140px 0 80px;
}
.about .bgc {
  background: #1b252f;
  color: #ffffff;
  width: 100%;
}
.work {
  background: #1b252f;
  color: rgb(225, 255, 255);
  padding: 80px 0 60px;
}

.contact {
  background: #f86d56;
  color: rgb(255, 255, 255);
  padding: 52px 0;
}


/* NAvbar Ham styling */

.navbar-container {
  display: flex;
  justify-content: space-between;
  /* padding-top: 1rem; */
}

.div1 {
  cursor: pointer;
  width: 25px;
  position: relative;
  z-index: 3;
  display: none;
}

.sub1, .sub2, .sub3 {
  width: 25px;
  height: 5px;
  margin-bottom: 5px;
  background-color: #ff5015;
  border-radius: 5px;
  transition: all .2s ease-in-out;
}

.div1.cross div {
  background: #ffffff;
}

.div1.cross .sub2{
  background: transparent;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.div1.cross .sub1 {
  transform: rotate(45deg) translate(7px, 7px);
}

.div1.cross .sub3 {
  transform: rotate(-45deg) translate(7px, -7px);
}

.sidebar {
  position: absolute;
  z-index: 2;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: -100%;
  transition: 0.3s;
}
.sidebar.active {
  right: 0;
  transition: 0.3s;
  background: #ff5015;
}
.sidebar .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar .social-link div {
  width: 50%;
  margin: auto;
  color: #ffffff;
}
.show {
  display: none;
}
.sidebar-list {
  list-style-type: none;
  padding: 0;
  text-align: center;
}
.sidebar-list li a{
  color: #ffffff;
	font-family: 'Montserrat', sans-serif;
  font-size: 2.6rem;
  font-weight: bold;
  text-decoration: none;
}

/* Navabr Ham styling ends */

@media (min-width: 1372px) {
  .home {
    background-size: 100% auto;
  }
}

@media (max-width: 1000px) {

  /* Navbar ham styling */

  .nav-ham ul {
    display: none;
  }
  .navbar .nav-ham .social-links {
    display: none;
  }
  .sidebar .social-links a {
    color: #ff5015;
    font-size: 18px;
  }
  .div1 {
    display: block;
  }
  .hidden {
    display: none;
  }
  .show {
    display: block;
  }

  /* Navbar ham styling ends */

  .navbar .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .home {
    height: 80vh;
    padding-top: 6rem;
  }
  .home .container {
    position: absolute;
    top: 70%;
    left: 50%;
  }
  .home .credit {
    background: #ffffff;
    border: #ff5015 2px solid;
    border-radius: 20px;
    margin: 0 auto;
    width: 100%;
    padding: 15px 0 8px 0;
  }
  .home .main-text {
    margin-top: 75px;
    padding: 40px 0;
  }
  .home .container img {
    width: 220px;
  }
  .vsdiv .vidhansabha {
    bottom: 120px;
  }  
  .home .vidhansabha .row {
    margin-right: 0;
  }
  .vsdiv .vidhansabha .col-lg-5 {
    border-right: none;
    margin-bottom: 20px;
  }
  /* .vsdiv .vidhansabha .col-lg-5 div {
    border-bottom: #ff5015 4px solid;
    padding-bottom: 10px;
  } */
  .vsdiv .container {
    font-size: 24px;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }
  .heading2 {
    font-size: 22px;
  }
  .heading4 {
    font-size: 18px;
  }
  .home-about {
    padding: 0 0 100px;
    text-align: center;
  }
  .vsdiv img {
    width: 40%;
  }
  .about {
    padding: 86px 0 0px;
  }
  .services {
    height: 560px;
  }
}

@media (max-width: 575px) {
    .vsdiv img {
      width: 30%;
    }
}


.raktmani {
  padding: 140px 0 180px;
}
.txtbox {
  display: inline;
  background: #363636;
  color: #ffffff;
  font-size: 10px;
  padding: 4px;
}


.hidden {
  display: none;
}